
import { HSBanner } from '@healthscholars/healthscholars-vue'
import { HSTitle } from '@healthscholars/healthscholars-vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HomeView',
  components: {
    HSBanner,
    HSTitle,
  },
  data() {
    return {
      userManager: process.env.VUE_APP_UM_URI,
      customerSuccessManager: process.env.VUE_APP_CSM_URI,
    }
  },
})
