<template>
  <div>
    <hs-styling />
    <HSNavbar class="navbar" />
    <router-view />
  </div>
</template>

<script>
import { HSStyling } from '@healthscholars/healthscholars-vue'
import { HSNavbar } from '@healthscholars/healthscholars-vue'

export default {
  components: {
    'hs-styling': HSStyling,
    HSNavbar,
  },
}
</script>

<style>
button[data-test='login'] {
  display: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
